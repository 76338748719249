import React, { useEffect, useRef } from "react";
import {
	AspectRatio,
	Flex,
	HStack,
	Icon,
	Image,
	Text,
	VStack,
	useBreakpointValue,
	usePrefersReducedMotion,
} from "@chakra-ui/react";
import { useState } from "react";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";

import { ItemProps } from "~/utils/Types/Home/YoutubeLive";
import { useControlLiveView } from "~/contexts/ControlLiveViewContext";

import { ActionButtons } from "./Buttons/ActionButtons";

type LivePopupProps = {
	items: ItemProps[];
};

const changeColor = `
  from { background-color: #63171B; }
  to { background-color: red; }
`

export function LivePopup({ items }: LivePopupProps) {
	const [expand, setExpand] = useState(false);
	const [close, setClose] = useState(false);
	const [onHover, setOnHover] = useState(false);
	const isWideVersion = useBreakpointValue({
		base: false,
		lg: true,
	});
	const isShortVersion = useBreakpointValue({
		base: false,
		md: true,
	});

	const { isVisible } = useControlLiveView()
	const popupRef = useRef<HTMLDivElement>(null)

	const prefersReducedMotion = usePrefersReducedMotion()

	const animation = prefersReducedMotion
		? undefined
		: `${changeColor} infinite 0.75s alternate-reverse`

	useEffect(() => {
		if (isVisible) {
			popupRef.current?.querySelectorAll('iframe').forEach(v => { v.src = v.src })
		}
	}, [isVisible])

	return (
		<Flex
			// bg={!close ? "#000" : "backgroundHeader"}
			display={isVisible ? "none" : "inherit"}
			pos="fixed"
			w={expand ? "700px" : "400px"}
			h={expand ? "500px" : "inherit"}
			maxW="95%"
			bottom={0}
			right={isShortVersion ? 8 : 1}
			zIndex={10}
			// borderRadius="6px 6px 0px 0px"
			roundedTop="xl"
			boxShadow="0px -11px 23px 5px rgba(0,0,0,0.14)"
			cursor="pointer"
			onClick={() => (close ? setClose(false) : null)}
			ref={popupRef}
		>
			<HStack
				py="1"
				px='4'
				w="100%"
				gap={2}
				color="white"
				align="center"
				roundedTop="xl"
				justify="flex-start"
				bg="backgroundLiveHeader"
				onClick={() => setClose((p) => !p)}
				borderBottom={close ? "1px dashed #747474" : "none"}
			>
				<Icon as={close ? RiArrowUpSLine : RiArrowDownSLine} fontSize="3xl" />
				<Text textStyle="textLG" color="white" mb="-1">Canal</Text>
				<Image src="/logoSALive.png" h="7" alt="Logo SA Live" />
				<Flex
					w="10px"
					h="10px"
					rounded="full"
					bg="red.900"
					ml="auto"
					animation={animation}
				/>
				<Text textStyle="textLG" color="white" mb="-1">ESTAMOS AO VIVO</Text>
			</HStack>

			{!close && (
				<VStack
					w="100%"
					// h={!expand ? "180px" : "400px"}
					bg="#fff"
					pos="relative"
					borderBottom="1px solid #747474"
					onMouseEnter={() => setOnHover(true)}
					onMouseLeave={() => setOnHover(false)}
				>
					{onHover && isWideVersion && (
						<ActionButtons
							expand={expand}
							setClose={setClose}
							setExpand={setExpand}
						/>
					)}

					<AspectRatio
						ratio={16 / 9}
						w="100%"
						css={{
							iframe: {
								width: "100%",
								height: "100%",
							},
						}}
					>
						<iframe
							title={items[0].snippet.title}
							src={`https://www.youtube.com/embed/${items[0]?.id.videoId}?autoplay=1&mute=1`}
							allowFullScreen
						/>
					</AspectRatio>
				</VStack>
			)}
			<VStack
				p={2}
				gap={2}
				w="100%"
				bg="black"
				align="flex-start"
			>
				<Text noOfLines={1} color="#fff" fontWeight="700">
					{items[0].snippet.title}
				</Text>
			</VStack>
		</Flex>
	);
}
