import { Accordion } from "@chakra-ui/react";
import { FC } from "react";
import { BiNews, BiSort } from "react-icons/bi";
import {
	RiBallPenLine,
	RiBookReadLine,
	RiFolder3Line,
	RiHome2Line,
	RiImage2Line,
	RiMenuLine,
	RiNewspaperLine,
	RiPagesLine,
	RiPushpinLine,
	RiRoadMapLine,
	RiSettings5Line,
	RiShoppingBasket2Line,
	RiUserLine,
	RiVidicon2Line,
} from "react-icons/ri";
import { AccordionSection } from "./AccordionSection";
import { NavLink } from "./NavLink";
import { NavSection } from "./NavSection";
import { IoMdNotificationsOutline } from "react-icons/io";
import { FaWpforms } from "react-icons/fa";

interface SidebarNavProps {
	onCloseSidebar?: () => void;
}

export const SidebarNav: FC<SidebarNavProps> = ({ onCloseSidebar }) => {
	return (
		<Accordion w="100%" allowToggle>
			<NavSection>
				<NavLink
					icon={RiHome2Line}
					href="/admin"
					shouldMatchExactHref={true}
					onCloseSidebar={onCloseSidebar}
				>
					Início
				</NavLink>
				<AccordionSection
					title="Conteúdos"
					icon={RiNewspaperLine}
					permissionsLink={["posts_view", "posts_create", "pages_edit"]}
				>
					<NavLink
						href="/admin/posts"
						onCloseSidebar={onCloseSidebar}
						permissionLink="posts_view"
					>
						Publicações
					</NavLink>
					<NavLink
						href={`/admin/display-posts`}
						onCloseSidebar={onCloseSidebar}
						permissionLink="display_posts_view"
					>
						Display publicações
					</NavLink>
					<NavLink
						href={`/admin/display-news`}
						permissionLink="display_news_view"
						onCloseSidebar={onCloseSidebar}
					>
						Página de notícias
					</NavLink>
				</AccordionSection>
				<AccordionSection
					title="Prateleira"
					icon={RiShoppingBasket2Line}
					permissionsLink={["products_view", "product_forms_view"]}
				>
					<NavLink
						href="/admin/products"
						onCloseSidebar={onCloseSidebar}
						permissionLink="products_view"
					>
						Produtos
					</NavLink>
				</AccordionSection>
				<AccordionSection
					title="Sortimentos"
					icon={BiSort}
					permissionsLink={["sections_view"]}
				>
					<NavLink
						href="/admin/sections"
						onCloseSidebar={onCloseSidebar}
						permissionLink="sections_view"
					>
						Sessões
					</NavLink>
					<NavLink
						href="/admin/categories-sections"
						onCloseSidebar={onCloseSidebar}
						permissionLink="sections_view"
					>
						Categorias
					</NavLink>
					<NavLink
						href="/admin/areas"
						onCloseSidebar={onCloseSidebar}
						permissionLink="sections_view"
					>
						Áreas
					</NavLink>
					<NavLink
						href="/admin/companies"
						onCloseSidebar={onCloseSidebar}
						permissionLink="sections_view"
					>
						Empresas
					</NavLink>
				</AccordionSection>
				<NavLink
					icon={RiRoadMapLine}
					href="/admin/segmentations"
					onCloseSidebar={onCloseSidebar}
					permissionLink="segmentation_view"
				>
					Segmentações
				</NavLink>
				<NavLink
					icon={RiPagesLine}
					href="/admin/pages"
					onCloseSidebar={onCloseSidebar}
					permissionLink="pages_view"
				>
					Páginas
				</NavLink>
				<NavLink
					icon={RiBallPenLine}
					href="/admin/blogs"
					onCloseSidebar={onCloseSidebar}
					permissionLink="blogs_view"
				>
					Blogs
				</NavLink>
				<NavLink
					icon={RiBookReadLine}
					href="/admin/magazines"
					onCloseSidebar={onCloseSidebar}
					permissionLink="magazines_view"
				>
					Revistas
				</NavLink>
				<NavLink
					icon={FaWpforms}
					href="/admin/forms"
					onCloseSidebar={onCloseSidebar}
					permissionLink="product_forms_view"
				>
					Formulários
				</NavLink>
				<NavLink
					icon={IoMdNotificationsOutline}
					href="/admin/notifications"
					onCloseSidebar={onCloseSidebar}
					permissionLink="notification_view"
				>
					Notificações
				</NavLink>
				<NavLink
					icon={RiPushpinLine}
					href="/admin/themes"
					onCloseSidebar={onCloseSidebar}
					permissionLink="categories_view"
				>
					Temas
				</NavLink>
				<NavLink
					icon={RiVidicon2Line}
					href="/admin/videos"
					onCloseSidebar={onCloseSidebar}
					permissionLink="videos_view"
				>
					Vídeos
				</NavLink>
				<NavLink
					icon={RiImage2Line}
					href="/admin/medias"
					onCloseSidebar={onCloseSidebar}
					permissionLink="medias_view"
				>
					Mídia
				</NavLink>
				<NavLink
					icon={BiNews}
					href="/admin/newsletters"
					onCloseSidebar={onCloseSidebar}
					permissionLink="newsletters_view"
				>
					NewsLetters
				</NavLink>
				<NavLink
					// rolesLink={["ADMIN", "MASTER", "OWNER"]}
					permissionLink="repositories_view"
					icon={RiFolder3Line}
					href={`/admin/repositories`}
				>
					Repositório de Arquivos
				</NavLink>
				<AccordionSection
					title="Configurações"
					icon={RiSettings5Line}
					permissionsLink={[
						"platform_edit",
						"platform_view",
						"social_medias_view",
						"access_profiles_view",
						"menus_view",
						"carousel_view",
						"users_view",
						"arm_view",
						"form_magazine_view",
					]}
				>
					<NavLink
						href={`/admin/platform`}
						onCloseSidebar={onCloseSidebar}
						permissionLink="platform_view"
					>
						Plataforma
					</NavLink>
					<Accordion w="100%" allowToggle>
						<AccordionSection
							title="SEO"
							permissionsLink={[
								"platform_edit",
								"platform_view",
							]}
						>
							<NavLink
								href={`/admin/seo/posts`}
								onCloseSidebar={onCloseSidebar}
								permissionLink="platform_view"
								pl={8}
							>
								Notícias
							</NavLink>
							<NavLink
								href={`/admin/seo/solucaosortimento`}
								onCloseSidebar={onCloseSidebar}
								permissionLink="platform_view"
								pl={8}
							>
								Solução sortimento
							</NavLink>
							<NavLink
								href={`/admin/seo/prateleiras`}
								onCloseSidebar={onCloseSidebar}
								permissionLink="platform_view"
								pl={8}
							>
								Prateleiras
							</NavLink>
							<NavLink
								href={`/admin/seo/magazines`}
								onCloseSidebar={onCloseSidebar}
								permissionLink="platform_view"
								pl={8}
							>
								Revistas
							</NavLink>
						</AccordionSection>
					</Accordion>
					<NavLink
						href={`/admin/form-magazine`}
						onCloseSidebar={onCloseSidebar}
						permissionLink="form_magazine_view"
					>
						Formulário Revistas
					</NavLink>
					<NavLink
						href={`/admin/social-medias`}
						onCloseSidebar={onCloseSidebar}
						permissionLink="social_medias_view"
					>
						Mídias sociais
					</NavLink>
					<NavLink
						href={`/admin/access-profiles`}
						onCloseSidebar={onCloseSidebar}
						permissionLink="access_profiles_view"
					>
						Perfis de acesso
					</NavLink>
					<NavLink
						href="/admin/menus"
						onCloseSidebar={onCloseSidebar}
						permissionLink="menus_view"
					>
						Menu
					</NavLink>
					<NavLink
						href="/admin/carousel"
						onCloseSidebar={onCloseSidebar}
						permissionLink="carousel_view"
					>
						Carrossel
					</NavLink>
					<NavLink
						href="/admin/arm"
						onCloseSidebar={onCloseSidebar}
						permissionLink="arm_view"
					>
						Braços
					</NavLink>
					<NavLink
						href="/admin/users"
						onCloseSidebar={onCloseSidebar}
						permissionLink="users_view"
					>
						Usuários
					</NavLink>
					<NavLink
						href="/admin/leads-newsletter"
						onCloseSidebar={onCloseSidebar}
						permissionLink="users_view"
					>
						Leads Newsletters
					</NavLink>
					<NavLink
						href="/admin/user-mysa"
						onCloseSidebar={onCloseSidebar}
						permissionLink="users_view"
					>
						Usuários MySa
					</NavLink>
				</AccordionSection>
			</NavSection>
		</Accordion>
	);
};
