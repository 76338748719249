import {
	Flex,
	IconButton,
	Image,
	useBreakpointValue,
	Icon,
	Grid,
	Box,
	Text,
	Menu,
	MenuButton,
	MenuList,
	MenuItem
} from '@chakra-ui/react'
import Router from 'next/router'

import { RiMenuLine } from 'react-icons/ri'
import { IoIosArrowDown } from 'react-icons/io'

import { queryClient } from '~/services/queryClient'
import { useAuthContext } from '~/contexts/AuthContext'
import { useSidebarDrawer } from '~/contexts/SideBarDrawerContext'

interface HeaderProps {
}

export const HeaderAdmin = ({ }: HeaderProps) => {
	const { onOpen } = useSidebarDrawer()
	const { signOut, user } = useAuthContext()

	const isWideVersion = useBreakpointValue({
		base: false,
		xl: true,
	})

	return (
		<Flex
			as="header"
			w="100%"
			h="20"
			px="6"
			bg="white"
			justifyContent="space-between"
			align="center"
			top="0"
			position="sticky"
			zIndex={100}
			opacity="1"
			visibility="visible"
			ml="auto"
		>
			{!isWideVersion && (
				<IconButton
					aria-label="Open navigation"
					icon={<Icon as={RiMenuLine} />}
					fontSize="24"
					variant="unstyled"
					color="primaryText"
					onClick={onOpen}
					mr="2"
				/>
			)}
			<Image
				src="/logoSAVarejo.png"
				alt="Logo SA+ Ecossistema de Varejo"
				// maxW="120px"
				objectFit="contain"
				cursor="pointer"
				onClick={() => Router.push('/')}
			/>

			<Flex align="center">
				<Grid
					gridTemplateColumns="1fr 1.5rem"
					alignItems="center"
					height="20"
					gap="2"
				>
					<Box>
						<Text
							textStyle="headerMD"
							noOfLines={1}
							as="header"
						>
							{user?.name}
						</Text>
						<Text
							textStyle="textXS"
						>
							Meus dados
						</Text>
					</Box>
					<Menu>
						{({ isOpen: isOpenMenu }) => (
							<>
								<MenuButton>
									<Icon
										as={IoIosArrowDown}
										fontSize="24"
										cursor="pointer"
									/>
								</MenuButton>
								{isOpenMenu && (
									<MenuList mt="7">
										<MenuItem onClick={() => Router.push('/')}>
											Home
										</MenuItem>
										<MenuItem onClick={() => Router.push('/admin/profile')}>
											Meus dados
										</MenuItem>
										<MenuItem onClick={() => {
											queryClient.clear()
											signOut()
										}}>
											Sair
										</MenuItem>
									</MenuList>
								)}
							</>
						)}
					</Menu>
				</Grid>
			</Flex>
		</Flex>
	)
}
