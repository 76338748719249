import { useRouter } from "next/router"
import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useEffect, useState } from "react"

type DefaultValuesProps = {
	limit?: number
	search?: string
	page?: number
	field?: string
	direction: 'asc' | 'desc'
}

type ControlFiltersContextData = {
	page: number
	setPage: Dispatch<SetStateAction<number>>
	limit: number
	setLimit: Dispatch<SetStateAction<number>>
	search: string | undefined
	setSearch: Dispatch<SetStateAction<string | undefined>>
	field: string
	setField: Dispatch<SetStateAction<string>>
	direction: "asc" | "desc"
	setDirection: Dispatch<SetStateAction<"asc" | "desc">>
	fieldSelect: string
	setFieldSelect: Dispatch<SetStateAction<string>>
	multiFilters: any
	setMultiFilters: Dispatch<any>
	setDefaultValues: Dispatch<SetStateAction<DefaultValuesProps | undefined>>
	initialDate: string
	setInitialDate: Dispatch<SetStateAction<string>>
	finalDate: string
	setFinalDate: Dispatch<SetStateAction<string>>
	typePublish: "both" | "publish" | "draft" | undefined
	setTypePublish: Dispatch<SetStateAction<"both" | "publish" | "draft">>
}

type ControlFiltersProviderProps = {
	children?: ReactNode
}

const ControlFiltersContext = createContext({} as ControlFiltersContextData)

export const ControlFiltersProvider: FC<ControlFiltersProviderProps> = ({ children }) => {
	const router = useRouter()
	const [page, setPage] = useState(1)
	const [limit, setLimit] = useState(25)
	const [search, setSearch] = useState<string>()
	const [field, setField] = useState<string>('id')
	const [direction, setDirection] = useState<'asc' | 'desc'>('desc')
	const [defaultValues, setDefaultValues] = useState<DefaultValuesProps>()
	const [initialDate, setInitialDate] = useState<string>('')
	const [finalDate, setFinalDate] = useState<string>('')
	const [typePublish, setTypePublish] = useState<'both' | 'publish' | 'draft'>('both')
	const [multiFilters, setMultiFilters] = useState<any>({})

	const [fieldSelect, setFieldSelect] = useState<string>('')

	useEffect(() => {
		const resultPageStore = sessionStorage.getItem(`${router.asPath}-page`)
		if (!!resultPageStore) {
			setPage(Number(resultPageStore))
		} else {
			setPage(1)
		}

		const resultSearchStore = sessionStorage.getItem(`${router.asPath}-search`)
		if (!!resultSearchStore) {
			setSearch(resultSearchStore)
		} else if (!!search) {
			setSearch(undefined)
		}

		const resultLimitStore = sessionStorage.getItem(`${router.asPath}-limit`)
		if (!!resultLimitStore) {
			setLimit(Number(resultLimitStore))
		} else if (!!limit) {
			setLimit(25)
		}

		const resultFieldStore = sessionStorage.getItem(`${router.asPath}-field`)
		if (!!resultFieldStore) {
			setField(resultFieldStore)
		} else {
			setField('')
		}
		const resultDirectionStore = sessionStorage.getItem(`${router.asPath}-direction`)
		if (!!resultDirectionStore) {
			setDirection(resultDirectionStore as any)
		} else {
			setDirection('desc')
		}

		const resultFieldSelectStore = sessionStorage.getItem(`${router.asPath}-field-select`)
		if (!!resultFieldSelectStore) {
			setFieldSelect(resultFieldSelectStore as any)
		} else {
			setFieldSelect('')
		}

		const resultMultiFiltersStore = sessionStorage.getItem(`${router.asPath}-multi-filters`)
		if (!!resultMultiFiltersStore) {
			setMultiFilters(JSON.parse(resultMultiFiltersStore))
		} else {
			setMultiFilters({})
		}

		const resultinItialDateStore = sessionStorage.getItem(`${router.asPath}-initial-date`)
		if (!!resultinItialDateStore) {
			setInitialDate(resultinItialDateStore as any)
		} else {
			setInitialDate('')
		}

		const resultFinalDateStore = sessionStorage.getItem(`${router.asPath}-final-date`)
		if (!!resultFinalDateStore) {
			setFinalDate(resultFinalDateStore as any)
		} else {
			setFinalDate('')
		}

		const resultDateTypePublishStore = sessionStorage.getItem(`${router.asPath}-type-publish`)
		if (!!resultDateTypePublishStore) {
			setTypePublish(resultDateTypePublishStore as any)
		} else {
			setTypePublish('both')
		}
	}, [router.asPath])

	useEffect(() => {
		sessionStorage.setItem(`${router.asPath}-page`, String(page))
		if (search) {
			sessionStorage.setItem(`${router.asPath}-search`, search)
		} else {
			sessionStorage.removeItem(`${router.asPath}-search`)
		}
		sessionStorage.setItem(`${router.asPath}-limit`, String(limit))
		sessionStorage.setItem(`${router.asPath}-field`, String(field))
		sessionStorage.setItem(`${router.asPath}-direction`, String(direction))
		sessionStorage.setItem(`${router.asPath}-direction`, String(direction))
		sessionStorage.setItem(`${router.asPath}-field-select`, String(fieldSelect))
		sessionStorage.setItem(`${router.asPath}-multi-filters`, JSON.stringify(multiFilters))
		sessionStorage.setItem(`${router.asPath}-initial-date`, String(initialDate))
		sessionStorage.setItem(`${router.asPath}-final-date`, String(finalDate))
		sessionStorage.setItem(`${router.asPath}-type-publish`, String(typePublish))
	}, [page, limit, search, field, direction, fieldSelect, initialDate, finalDate, typePublish, multiFilters])

	useEffect(() => {
		if (defaultValues?.limit) setLimit(defaultValues.limit)
		if (defaultValues?.page) setPage(defaultValues.page)
		if (defaultValues?.search) setSearch(defaultValues.search)
		if (defaultValues?.field) setField(defaultValues.field)
		if (defaultValues?.direction) setDirection(defaultValues.direction)
	}, [defaultValues])

	return (
		<ControlFiltersContext.Provider value={{
			page,
			setPage,
			search,
			setSearch,
			limit,
			setLimit,
			setDefaultValues,
			direction,
			field,
			setDirection,
			setField,
			fieldSelect,
			setFieldSelect,
			multiFilters,
			setMultiFilters,
			finalDate,
			initialDate,
			setFinalDate,
			setInitialDate,
			setTypePublish,
			typePublish
		}}>
			{children}
		</ControlFiltersContext.Provider>
	)
}

export const useControlFilters = () => useContext(ControlFiltersContext)
