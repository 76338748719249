import axios from "axios"
import { parseCookies, setCookie } from "nookies"

import { api } from "~/services/apiClient"
import { queryClient } from "~/services/queryClient"

function getStateFromCoordinates(latitude: number, longitude: number) {
	return axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`)
		.then(response => response.data.address.state)
		.catch(error => {
			// console.error('Error getting state from coordinates:', error)
			return null
		})
}

function getUserLocation() {
	return new Promise((resolve, reject) => {
		if (typeof window !== "undefined" && "geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(
				position => {
					resolve(position.coords)
				},
				error => {
					// reject(error)
				}
			)
		} else {
			// reject(new Error("Geolocation is not available"))
		}
	})
}

export async function setUserState() {
	const { "@SAVarejo:user-state": hasStoredUserState } = parseCookies()

	if (!hasStoredUserState) {
		const userLocationPromise = getUserLocation()

		userLocationPromise.then(userLocation => {
			const { latitude, longitude } = userLocation as any

			getStateFromCoordinates(latitude, longitude)
				.then(state => {
					if (state) {
						api.defaults.headers.common['user-state'] = state

						setCookie(undefined, "@SAVarejo:user-state", state, {
							maxAge: 604800, // 7 days
							path: "/",
						})

						queryClient.invalidateQueries(['Portal'])
					}
				})
				.catch(error => {
					// console.error('Error:', error)
				})
		}).catch(error => {
			// console.error('Error getting user location:', error)
		})
	}
}
