import axios, { AxiosError } from 'axios'
import { GetServerSidePropsContext } from 'next'

import { parseCookies } from 'nookies'

import { removeCookies } from '~/contexts/AuthContext'
import { AuthTokenError } from '~/services/errors/AuthTokenError'

export function setupApiClient(ctx: GetServerSidePropsContext | undefined) {
	let cookies = parseCookies(ctx)

	const api = axios.create({
		baseURL: process.env.NEXT_PUBLIC_API_URL,
		headers: {
			'Accept-Encoding': 'gzip, compress, br',
		}
	})

	// api.defaults.headers.common['accept-encoding'] = '*'
	api.defaults.headers.common['user-state'] = cookies['@SAVarejo:user-state']

	if (!!ctx?.req.headers) {
		api.defaults.headers.common['Authorization'] = `Bearer ${cookies['@SAVarejo:token']}`
		api.defaults.headers.common['origin'] = ctx.req.headers.host!
	} else {
		api.defaults.headers.common['Authorization'] = `Bearer ${cookies['@SAVarejo:token']}`
	}

	api.interceptors.response.use(response => {
		return response
	}, (error: AxiosError) => {
		if (error?.response?.status === 401) {
			if (!(typeof window === 'undefined')) {
				removeCookies()
			} else {
				return Promise.reject(new AuthTokenError)
			}
		}

		return Promise.reject(error)
	})

	return api
}
