import { FC, ReactNode } from "react";

import { AuthProvider } from "./AuthContext";
import { ControlFiltersProvider } from "./ControlFiltersContext";
import { SideBarDrawerProvider } from "./SideBarDrawerContext";
import { ControlLiveViewProvider } from "./ControlLiveViewContext";
import { SettingsHomeProps } from "~/utils/Types/Home";

interface Props {
	children: ReactNode;
	settings?: SettingsHomeProps;
}

export const AppProvider: FC<Props> = ({ settings, children }) => (
	<AuthProvider settings={settings}>
		<SideBarDrawerProvider>
			<ControlFiltersProvider>
				<ControlLiveViewProvider>{children}</ControlLiveViewProvider>
			</ControlFiltersProvider>
		</SideBarDrawerProvider>
	</AuthProvider>
);
