import {
	Box,
	Flex,
	DarkMode
} from "@chakra-ui/react"
import Head from "next/head"
import { FC, ReactNode } from "react"

import { useAuthContext } from "~/contexts/AuthContext"

import { HeaderAdmin } from "~/components/Admin/Header"
import { SidebarAdmin } from "~/components/Admin/Sidebar"

interface BaseLayoutAdminProps {
	children?: ReactNode
}

const BaseLayoutAdmin: FC<BaseLayoutAdminProps> = ({ children }) => {
	const { platformFavicon } = useAuthContext()

	return (
		<>
			{!!platformFavicon && (
				<Head>
					<link
						rel="shortcut icon"
						href={platformFavicon}
						type="image/png"
					/>
				</Head>
			)}
			<Box minH="100vh" id="top">
				<HeaderAdmin />
				<Flex>
					<Box>
						<SidebarAdmin />
					</Box>
					<Box p={{ sm: 4, md: 6, lg: 10 }} w={{ sm: '100%', xl: 'calc(100% - 16rem)' }} ml="auto">
						{children}
					</Box>
				</Flex>
			</Box>
		</>
	)
}

export default BaseLayoutAdmin
