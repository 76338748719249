import { extendTheme, ThemeConfig } from "@chakra-ui/react";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";

const breakpoints = {
	sm: "20em", // 320px
	md: "30em", // 480px
	lg: "48em", // 768px
	xl: "62em", // 992px
	"2xl": "80em", // 1280px
	"3xl": "85.313em", // 1365px
};

const config: ThemeConfig = {
	initialColorMode: "light",
	useSystemColorMode: false,
};

const textStyles = {
	headerXL: {
		fontSize: { sm: "xl", md: "2xl" },
		fontWeight: "bold",
		lineHeight: "110%",
		letterSpacing: "-2%",
		color: "primaryText",
	},
	headerLG: {
		fontSize: { sm: "lg", md: "xl" },
		fontWeight: "bold",
		lineHeight: "110%",
		letterSpacing: "-2%",
		color: "primaryText",
	},
	headerMD: {
		fontSize: { sm: "md", md: "lg" },
		fontWeight: "bold",
		lineHeight: "110%",
		letterSpacing: "-2%",
		color: "primaryText",
	},
	headerSM: {
		fontSize: { sm: "sm", md: "md" },
		fontWeight: "medium",
		lineHeight: "110%",
		letterSpacing: "-2%",
		color: "primaryText",
	},
	textLG: {
		fontSize: { sm: "md", md: "lg" },
		fontWeight: "normal",
		lineHeight: "110%",
		letterSpacing: "-2%",
		color: "primaryText",
	},
	textMD: {
		fontSize: { sm: "sm", md: "md" },
		fontWeight: "normal",
		lineHeight: "110%",
		letterSpacing: "-2%",
		color: "primaryText",
	},
	textSM: {
		fontSize: { sm: "xs", md: "sm" },
		fontWeight: "normal",
		lineHeight: "110%",
		letterSpacing: "-2%",
		color: "primaryText",
	},
	textXS: {
		fontSize: { sm: "xs", md: "xs" },
		fontWeight: "normal",
		lineHeight: "120%",
		letterSpacing: "-5%",
		color: "primaryText",
	},
};

const layerStyles = {
	card: {
		bg: "white",
		rounded: "md",
		shadow: "md",
		color: "primaryText",
	},
	container: {
		margin: "0 auto",
		// maxWidth: "65em",
	},
	tabContainer: {
		borderBottom: "1px",
		borderX: "1px",
		borderColor: "gray.100",
		borderBottomRadius: "md",
	},
};

export const theme = extendTheme({
	breakpoints,
	textStyles,
	layerStyles,

	fonts: {
		heading: "League_Spartan, sans-serif",
		body: "League_Spartan, sans-serif",
	},

	semanticTokens: {
		colors: {
			primaryText: {
				default: "gray.900",
			},
			backgroundCard: {
				default: "white",
			},
			backgroundSubHeader: {
				default: "#1c1c1c",
			},
			backgroundHeader: {
				default: "#4f0d28",
			},
			backgroundLiveHeader: {
				default: "#de0044",
			},
			buttonColors: {
				"900": "#330017",
				"800": "#3f011b",
				"700": "#4a011f",
				"600": "#550123",
				"500": "#661336",
				"400": "#762848",
				"300": "#873d5a",
				"200": "#98516c",
				"100": "#a8657e",
				"50": "#b87a90",
			}
		},
	},

	colors: {
		switchInactive: {
			50: "#f7fafc",
			500: "#969696",
			900: "#969696",
		},
		switchActive: {
			50: "#f7fafc",
			500: "#e21350",
			900: "#969696",
		},
	},

	// components: {
	// 	Drawer: {
	// 		variants: {
	// 			permanent: {
	// 				dialog: {
	// 					pointerEvents: 'auto',
	// 				},
	// 				dialogContainer: {
	// 					pointerEvents: 'none',
	// 				},
	// 			},
	// 		},
	// 	},
	// },

	styles: {
		global: (props: StyleFunctionProps) => ({
			body: {
				bg: "gray.100",
				color: "gray.900",
			},
			option: {
				color: "gray.600",
			},
			"*::placeholder": {
				color: "gray.600",
			},
			h1: { fontSize: "4xl", fontWeight: "bold" },
			h2: {
				fontSize: "3xl",
				fontWeight: "bold",
			},
			h3: {
				fontSize: "2xl",
				fontWeight: "bold",
			},
			h4: {
				fontSize: "lg",
				fontWeight: "bold",
			},
			h5: {
				fontSize: "md",
				fontWeight: "bold",
			},
			h6: {
				fontSize: "sm",
				fontWeight: "bold",
			},
		}),
	},
	config,
});
