import {
	Icon,
	Link as ChakraLink,
	LinkProps as ChakraLinkProps,
	Text
} from '@chakra-ui/react'
import { ElementType } from 'react'

import NextLink from "next/link"
import { useRouter } from 'next/router'

import { useCan } from '~/hooks/useCan'
import { PERMISSIONS } from '~/utils/Types/Permissions'

interface NavLinkProps extends ChakraLinkProps {
	icon?: ElementType
	children: string
	href: string
	onCloseSidebar?: () => void
	shouldMatchExactHref?: boolean
	permissionLink?: PERMISSIONS
}

export const NavLink = ({ icon, onCloseSidebar, children, href, shouldMatchExactHref, permissionLink, ...rest }: NavLinkProps) => {
	let userHasValidPermission = true

	userHasValidPermission = useCan({ permissions: permissionLink ? [permissionLink] : undefined })

	const { asPath } = useRouter()

	let isActive = false

	if (shouldMatchExactHref && asPath === href || asPath === rest.as) {
		isActive = true
	}

	if (!shouldMatchExactHref &&
		(asPath.startsWith(String(href)) ||
			asPath.startsWith(String(rest.as)))) {
		isActive = true
	}

	return (
		<ChakraLink
			as={NextLink}
			href={href}
			p="2"
			rounded="lg"
			display={userHasValidPermission ? "flex" : "none"}
			color={isActive ? 'pink.600' : 'gray.600'}
			bg={isActive ? 'pink.50' : 'transparent'}
			_hover={{
				textDecoration: 'none',
				bg: 'pink.50'
			}}
			{...rest}
		>
			{icon && (
				<Icon onClick={onCloseSidebar} as={icon} fontSize="20" />
			)}
			<Text onClick={onCloseSidebar} ml={!icon ? "8" : "4"} fontWeight="medium">
				{children}
			</Text>
		</ChakraLink>
	)
}
