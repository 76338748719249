import { useBreakpointValue, useDisclosure, UseDisclosureReturn } from "@chakra-ui/react"
import { createContext, ReactNode, useContext, useEffect } from "react"

interface SideBarDrawerProviderProps {
	children: ReactNode
}

type SideBarDrawerContextData = UseDisclosureReturn

const SideBarDrawerContext = createContext({} as SideBarDrawerContextData)

export function SideBarDrawerProvider({ children }: SideBarDrawerProviderProps) {
	const disclosure = useDisclosure()

	const isWideVersion = useBreakpointValue({
		base: false,
		xl: true
	})

	useEffect(() => {
		if (isWideVersion) {
			disclosure.onOpen()
		} else {
			disclosure.onClose()
		}
	}, [isWideVersion])

	return (
		<SideBarDrawerContext.Provider
			value={disclosure}
		>
			{children}
		</SideBarDrawerContext.Provider>
	)
}

export const useSidebarDrawer = () => useContext(SideBarDrawerContext)
