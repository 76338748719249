import { PERMISSIONS } from "../Types/Permissions"
import { ROLES } from "../Types/Roles"
import { UserAuthProps } from "../Types/User"

type ValidadeUserPermissionsParams = {
	user: UserAuthProps
	roles?: ROLES[]
	permissions?: PERMISSIONS[]
}

export function validateUserPermissions({
	user,
	roles,
	permissions
}: ValidadeUserPermissionsParams) {
	if (roles) {
		const hasAllRoles = roles.some(roles => {
			return user.roles.includes(roles)
		})

		if (!hasAllRoles) {
			return false
		}
	}

	if (permissions) {
		if (user.roles.includes('MASTER') || user.roles.includes('MASTER')) {
			return true
		}
		const hasAllPermissions = permissions.some(permissions => {
			return user.permissions.includes(permissions)
		})

		if (!hasAllPermissions) {
			return false
		}
	}

	return true
}
