import { ElementType, ReactNode } from 'react'
import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, HStack, Icon, Stack, Text } from '@chakra-ui/react'
import { PERMISSIONS } from '~/utils/Types/Permissions'
import { useCan } from '~/hooks/useCan'

interface AccordionSectionProps {
	title?: string
	children: ReactNode
	permissionsLink?: PERMISSIONS[]
	icon?: ElementType
}

export const AccordionSection = ({ title, children, permissionsLink, icon }: AccordionSectionProps) => {
	let userHasValidPermission = true

	userHasValidPermission = useCan({ permissions: !!permissionsLink ? [...permissionsLink] : undefined })

	return (
		<AccordionItem border="0" w="100%" display={!userHasValidPermission ? "none" : undefined}>
			<AccordionButton
				p="2"
				rounded="lg"
				display={userHasValidPermission ? "flex" : "none"}
				color="gray.600"
				bg="transparent"
				_hover={{
					textDecoration: 'none',
					bg: 'pink.50'
				}}
			>
				<HStack spacing="4">
					{icon && (
						<Icon as={icon} fontSize="20" />
					)}
					<Text ml={!icon ? "8" : undefined} fontWeight="medium">
						{title}
					</Text>
				</HStack>
				<Flex w="100%" justify="flex-end">
					<AccordionIcon />
				</Flex>
			</AccordionButton>
			<AccordionPanel p="0">
				<Stack spacing="2" my="2" align="stretch">
					{children}
				</Stack>
			</AccordionPanel>
		</AccordionItem>
	)
}
