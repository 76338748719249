import React, { Component, ErrorInfo, ReactNode } from "react"

interface Props {
	children: ReactNode
}

interface State {
	hasError: boolean
	error?: Error
	errorComponentDidCatch?: Error
	errorInfoComponentDidCatch?: ErrorInfo
}

export class ErrorBoundary extends Component<Props, State> {
	public state: State = {
		hasError: false,
		error: undefined,
		errorComponentDidCatch: undefined,
		errorInfoComponentDidCatch: undefined
	}

	public static getDerivedStateFromError(error: Error): State {
		// Update state so the next render will show the fallback UI.
		return { hasError: true, error: error }
	}

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error("error:", error, errorInfo, errorInfo.componentStack)
		console.error("errorInfo:", error, errorInfo, errorInfo.componentStack)
		console.error("errorInfo.componentStack:", error, errorInfo, errorInfo.componentStack)
	}

	public render() {
		if (this.state.hasError) {
			return (
				<div>
					<h1>erro inesperado</h1>
				</div>
			)
		}

		return this.props.children
	}
}
