import {
	useBreakpointValue, Drawer, DrawerOverlay,
	DrawerContent, DrawerCloseButton, DrawerHeader,
	DrawerBody, VStack
} from '@chakra-ui/react'
import { FC } from 'react'
import { useSidebarDrawer } from '~/contexts/SideBarDrawerContext'
import { SidebarNav } from './SidebarNav'

interface SidebarAdminProps {
}

export const SidebarAdmin: FC<SidebarAdminProps> = ({ }) => {
	const { isOpen, onClose } = useSidebarDrawer()

	const isDrawerSidebar = useBreakpointValue({
		base: false,
		xl: true
	})

	if (!isDrawerSidebar) {
		return (
			<Drawer isOpen={isOpen} placement="left" onClose={onClose}>
				<DrawerOverlay>
					<DrawerContent bg="white" p="4">
						<DrawerCloseButton color="primaryText" mt="6" />
						<DrawerHeader color="primaryText">Navegação</DrawerHeader>
						<DrawerBody>
							<SidebarNav onCloseSidebar={onClose} />
						</DrawerBody>
					</DrawerContent>
				</DrawerOverlay>
			</Drawer>
		)
	}

	return (
		<VStack
			as="aside"
			w="16rem"
			height="calc(100vh - 5rem)"
			position="fixed"
			bg="white"
			align="flex-start"
			overflowY="scroll"
			css={{
				'&::-webkit-scrollbar': {
					width: '4px',
				},
				'&::-webkit-scrollbar-track': {
					width: '6px',
				},
				'&::-webkit-scrollbar-thumb': {
					background: '#333',
					borderRadius: '24px',
				},
			}}
		>
			<SidebarNav onCloseSidebar={onClose} />
		</VStack>
	)
}
