import { useRouter } from "next/router"
import { createContext, ReactNode, useContext, useEffect, useRef, useState, RefObject, useCallback } from "react"

interface ControlLiveViewProviderProps {
	children: ReactNode
}

type ControlLiveViewContextData = {
	componentRef: RefObject<HTMLDivElement>
	isVisible: boolean
}

const ControlLiveViewContext = createContext({} as ControlLiveViewContextData)

export function ControlLiveViewProvider({ children }: ControlLiveViewProviderProps) {
	const componentRef = useRef<HTMLDivElement>(null)
	const [isVisible, setIsVisible] = useState(true)
	const router = useRouter()

	useEffect(() => {
		const handleScroll = () => {
			if (componentRef.current) {
				const rect = componentRef.current.getBoundingClientRect()
				setIsVisible(rect.top < window.innerHeight && rect.bottom >= 0)
			}
		}

		window.addEventListener('scroll', handleScroll)
		handleScroll() // Verifique a visibilidade inicial

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	useEffect(() => {
		if (!isVisible) {
			componentRef.current?.querySelectorAll('iframe').forEach(v => { v.src = v.src })
		}
	}, [isVisible])

	useEffect(() => {
		setIsVisible(!!componentRef.current)
	}, [router.asPath])

	return (
		<ControlLiveViewContext.Provider
			value={{
				componentRef,
				isVisible
			}}
		>
			{children}
		</ControlLiveViewContext.Provider>
	)
}

export const useControlLiveView = () => useContext(ControlLiveViewContext)
