import { useAuthContext } from "~/contexts/AuthContext"
import { PERMISSIONS } from "~/utils/Types/Permissions"
import { ROLES } from "~/utils/Types/Roles"
import { validateUserPermissions } from "~/utils/Validator/validadeUserPermissions"

type UseCanParams = {
	permissions?: PERMISSIONS[]
	roles?: ROLES[]
}

export function useCan({ permissions, roles }: UseCanParams) {
	const { user, isAuthenticated } = useAuthContext()

	if (!isAuthenticated || !user) {
		return false
	}

	const userHasValidPermissions = validateUserPermissions({
		user,
		permissions,
		roles
	})

	return userHasValidPermissions
}
