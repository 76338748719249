import { ReactNode } from 'react'
import { Box, Stack, Text } from '@chakra-ui/react'

interface NavLinkProps {
	title?: string
	children: ReactNode
}

export const NavSection = ({ title, children }: NavLinkProps) => {
	return (
		<Box w="100%" px="4">
			{title && (
				<Text fontWeight="bold" fontSize="small">
					{title}
				</Text>
			)}
			<Stack spacing="2" mt="8" align="stretch">
				{children}
			</Stack>
		</Box>
	)
}
