import { useToast } from "@chakra-ui/react";
import {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";

import Router, { useRouter } from "next/router";
import { userAgentFromString } from "next/server";

import { AxiosResponse } from "axios";
import { BroadcastChannel } from "broadcast-channel";
import { destroyCookie, parseCookies, setCookie } from "nookies";

import { api } from "../services/apiClient";
import { UserAuthProps } from "~/utils/Types/User";
import { setUserState } from "~/utils/Functions/GeolocationFunctions"
import { SettingsHomeProps } from "~/utils/Types/Home";

type SignInCredentials = {
	email: string;
	password: string;
	redirect?: string;
};

type AuthContextData = {
	platformFavicon: string
	isAuthenticated: boolean;
	user: UserAuthProps | undefined;
	signIn: (credentials: SignInCredentials) => Promise<any>;
	signOut: () => void;
	socialSignOut: () => void;
	socialSignInStore: (data: any, redirect?: string) => void;
	settings?: SettingsHomeProps;
};

type AuthProviderProps = {
	children: ReactNode;
	settings?: SettingsHomeProps;
};

const AuthContext = createContext({} as AuthContextData);

let authChannel: BroadcastChannel;

function checkIsSafari() {
	return userAgentFromString(undefined).browser.name === "Safari";
}

export function removeCookies() {
	destroyCookie(undefined, "@SAVarejo:token", {
		path: "/",
	});
	destroyCookie(undefined, "@SAVarejo:social", {
		path: "/",
	});
	if (!checkIsSafari()) {
		authChannel.postMessage("signOut");
	}
}

export function signOut(route?: string) {
	api.delete("v1/sessions").then(() => {
		removeCookies();
		Router.push(route ? route : "/login");
	});
}

export function socialSignOut() {
	removeCookies()
}

export function AuthProvider({ settings, children }: AuthProviderProps) {
	const toast = useToast();
	const router = useRouter();
	const [user, setUser] = useState<UserAuthProps>();
	const [platformFavicon, setPlatformFavicon] = useState("")
	const isAuthenticated = !!user;

	useEffect(() => {
		if (typeof window !== "undefined" && !!settings?.azure_maps) setUserState()
	}, [])

	useEffect(() => {
		if (!checkIsSafari()) {
			authChannel = new BroadcastChannel("auth");

			authChannel.onmessage = (message) => {
				switch (message.data) {
					case "signOut":
						signOut();
						break;
					default:
						break;
				}
			};
		}
	}, [router.asPath]);

	useEffect(() => {
		const { "@SAVarejo:token": token } = parseCookies();
		const { "@SAVarejo:social": isSocialLogin } = parseCookies();

		if (token) {
			api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
			api
				.get("/v1/me")
				.then((response: AxiosResponse) => {
					const {
						platformFavicon,
						permissions,
						roles,
						user: { secureId, name, email, createdAt, avatar, company, companySegment, numberOfEmployees, activityInCompany },
					} = response.data;
					setUser({
						secureId,
						name,
						email: email,
						company: company,
						companySegment: companySegment,
						numberOfEmployees: numberOfEmployees,
						activityInCompany: activityInCompany,
						permissions,
						avatar,
						roles,
						isSocialLogin: !!isSocialLogin?.length,
						createdAt,
					});
					if (platformFavicon) setPlatformFavicon(platformFavicon)
				})
				.catch((e) => {
					if (!e?.response || !(e?.response?.status >= 500)) {
						signOut(!!isSocialLogin?.length ? "/" : "/login");
					}
				});
		} else {
			setUser(undefined);
		}
	}, [router.asPath]);

	const signIn = useCallback(
		async ({ email, password, redirect }: SignInCredentials) => {
			try {
				const response: AxiosResponse = await api.post("/v1/sessions", {
					email,
					password,
				});

				const {
					token,
					roles,
					user: { secureId, name, email: emailReturn, createdAt, avatar, company, companySegment, numberOfEmployees, activityInCompany },
				} = response.data;

				setCookie(undefined, "@SAVarejo:token", token.token, {
					maxAge: 604800, // 7 days
					path: "/",
				});

				setUser({
					secureId,
					name: name,
					avatar,
					email: emailReturn,
					company: company,
					companySegment: companySegment,
					numberOfEmployees: numberOfEmployees,
					activityInCompany: activityInCompany,
					permissions: [],
					roles,
					createdAt
				});

				api.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

				if ((roles.includes("MASTER") || roles.includes("ADMIN")) && !redirect?.length) {
					Router.push(`/admin`);
				} else {
					Router.push(redirect ? redirect : `/`);
				}
			} catch (error: any) {
				toast({
					title: error?.response?.data?.message || "E-mail ou senha inválidos",
					position: "top-right",
					status: "error",
					isClosable: true,
				});
				return;
			}
		},
		[]
	);

	const socialSignInStore = useCallback((data: any) => {
		try {
			const {
				token,
				roles,
				userLogged: { secureId, name, email: emailReturn, createdAt, avatar, company, companySegment, numberOfEmployees, activityInCompany },
			} = data;

			setCookie(undefined, "@SAVarejo:token", token.token, {
				maxAge: 604800, // 7 days
				path: "/",
			});
			setCookie(undefined, "@SAVarejo:social", "true", {
				maxAge: 604800, // 7 days
				path: "/",
			});
			setUser({
				secureId,
				isSocialLogin: true,
				name: name,
				avatar,
				email: emailReturn,
				company: company,
				companySegment: companySegment,
				numberOfEmployees: numberOfEmployees,
				activityInCompany: activityInCompany,
				permissions: [],
				roles,
				createdAt
			});

			api.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

			if (roles.includes("USER")) {
				Router.push(`/profile`);
			}
		} catch (error: any) {
			toast({
				title: error?.response?.data?.message || "E-mail ou senha inválidos",
				position: "top-right",
				status: "error",
				isClosable: true,
			});
			return;
		}
	}, []);

	return (
		<AuthContext.Provider
			value={{
				user,
				signIn,
				signOut,
				settings,
				socialSignOut,
				isAuthenticated,
				platformFavicon,
				socialSignInStore,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

export function useAuthContext() {
	return useContext(AuthContext);
}
