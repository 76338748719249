import React from "react";
import { Flex, HStack } from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";
import { BiExitFullscreen, BiFullscreen } from "react-icons/bi";

type ActionButtonsProps = {
	expand: boolean;
	setExpand: (value: React.SetStateAction<boolean>) => void;
	setClose: (value: React.SetStateAction<boolean>) => void;
};

export function ActionButtons({
	expand,
	setClose,
	setExpand,
}: ActionButtonsProps) {
	return (
		<HStack
			p={1}
			w="100%"
			pos="absolute"
			top={0}
			left={0}
			justify="space-between"
			zIndex={11}
		>
			<Flex cursor="pointer" onClick={() => setExpand((p) => !p)}>
				{expand ? (
					<BiExitFullscreen size="24px" color="#fff" />
				) : (
					<BiFullscreen size="20px" color="#fff" />
				)}
			</Flex>
			<Flex cursor="pointer" onClick={() => setClose((p) => !p)}>
				<AiOutlineClose size="20px" color="#fff" />
			</Flex>
		</HStack>
	);
}
